<template>
  <b-badge v-if="badge"
           :variant="color"
           pill
           class="position-absolute top-0 font-weight-bold"
           :class="{ 'border': color === 'light', 'right-0': !relative }"><slot></slot></b-badge>
  <span v-else
        :class="color"
        class="round xs position-absolute top-0 font-weight-bold"
        style="right:1px; font-size:10px;"><slot></slot></span>
</template>

<script>
export default {
  name: 'notificationBadge',
  props: {
    color: {
      type: String,
      default: 'danger'
    },
    badge: {
      type: Boolean,
      default: true
    },
    relative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.account-bg {
  background-color: $color-key-account;
}

.campaign-bg {
  background-color: $color-key-campaign;
}

.ad-group-bg {
  background-color: $color-key-adgroup;
}

.budget-bg {
  background-color: $color-key-budget;
}
</style>
